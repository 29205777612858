import * as React from 'react';
import {Button, Form, Row} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {UserService} from '../../user/UserService';
import {toast} from 'react-toastify';
import {User} from '../../../model/user/User';
import {CampaignsService} from '../../campaigns/CampaignsService';
import {Campaign} from '../../../model/campaign/Campaign';
import {getCorrectEnums} from "../../../utils/Functions";
import AutocompleteInput from '../../../shared/components/AutocompleteInput';

interface IOfferFilter {
    handleState: (...arg: any) => void;
    setState: (...arg: any) => void;
    offersFilter: any
}

export function OfferFilter({handleState, setState, offersFilter}: IOfferFilter) {
    const [users, setUsers] = useState<User[]>([]);
    const [campaigns, setCampaigns] = useState<Campaign[]>([]);
    const [userFilter, setUserFilter] = useState("");
    const [campaignFilter, setCampaignFilter] = useState("");

    const {offerStatus} = useSelector((offersFilter: any)=> offersFilter.enums);

    useEffect(() => {
        UserService.getUsers({perPage: 15, displayName: userFilter})
            .then((data) => {
                setUsers(data.data);
            })
            .catch((err) => {
                toast.error(`${err.response.data.message}`)
            });
    }, [userFilter]);

    useEffect(() => {
        CampaignsService.getCampaigns({perPage: 15, title: campaignFilter})
            .then((data) => {
                setCampaigns(data.data);
            })
            .catch((err) => {
                toast.error(`${err.response.data.message}`)
            });
    }, [campaignFilter]);

    function handleChanges(evt: any, ) {
        const value = evt.target.value;
        setState({
            ...offersFilter,
            [evt.target.name]: value,
        });
    }

    return(
        <Form>
            <Row>
                <div className="form-group col-md-3 col-sm-12">
                    <Form.Control placeholder='Offer ID' className='form-control-solid' name="id" onChange={handleChanges}/>
                </div>
                <div className="form-group col-md-5 col-sm-12">
                    <Form.Control className='form-control-solid' type='date' name="date" onChange={handleChanges} />
                </div>
                <div  className="form-group col-md-4 col-sm-12">
                    <Form.Control placeholder='Status' className='form-control-solid' name="status" onChange={handleChanges} as="select" >
                        <option value="">All statuses</option>
                        {offerStatus.map((status: string) => <option key={status} value={status}>{getCorrectEnums(status)}</option>)}
                    </Form.Control>
                </div>
            </Row>
            <Row>
                <div className="form-group col-md-6 col-sm-12">
                    <AutocompleteInput placeholder='Campaign title or id'
                                       onSelect={setState}
                                       setFilter={setCampaignFilter} 
                                       keyState={'campaignId'} 
                                       keyItemSelect={'id'} 
                                       value={campaignFilter} 
                                       items={campaigns} 
                                       hasClearBtn
                                       renderItems={['id','title']}
                                       style='zindex-5'
                                       clearFunc={() => {
                                            setState((prev: any) => ({...prev, campaignId: undefined}))
                                            setCampaignFilter('');
                                       }}
                    />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                    <AutocompleteInput placeholder='User Displayname'
                                       onSelect={setState}
                                       setFilter={setUserFilter} 
                                       keyState={'influencerId'} 
                                       keyItemSelect={'id'} 
                                       value={userFilter} 
                                       items={users} 
                                       hasClearBtn
                                       renderItems={['firstName','lastName']}
                                       style='zindex-5'
                                       clearFunc={() => {
                                            setState((prev: any) => ({...prev, influencerId: undefined}))
                                            setUserFilter('');
                                       }}
                    />
                </div>
            </Row>
            <Button className="mt-4" variant="primary" onClick={() => handleState(offersFilter)}>
                Search
            </Button>
        </Form>
    )
}
