import React from 'react'
import { toast } from 'react-toastify';
import { Button, Modal } from "react-bootstrap";
import { CompanyService } from '../CompanyService';
import { SuccessToast } from '../../../utils/toasters';

interface IModalDelete{
    showModal: boolean,
    isClient: boolean | undefined,
    title: string,
    setModalDeleteData: (args: any) => void,
    setChange: (args: any) => void,
    companyId: number,
}
export default function ModalDelete ({showModal, setModalDeleteData, title, companyId, setChange}:IModalDelete) {

    const deleteCompany = () => {
		CompanyService.deleteCompany(companyId)
			.then((data) => {
				SuccessToast('Successfully deleted company');
                setModalDeleteData((prev: any) => ({ ...prev, showModal: false }));
                setChange((prev: boolean) => !prev);
			})
			.catch((err) => {
				toast.error(`${err.response.data.message}`);
			});
	}

    return (
        <Modal
            show={showModal}
            onHide={() => {
                setModalDeleteData((prev: any) => ({ ...prev, showModal: false }))
            }}
            // size=""
            centered
            className='modal-dialog-centered'
            aria-labelledby="contained-modal-title-vcenter"
        >
        <Modal.Body>
          <h4>            
            Are you sure to delete {title} company?
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='danger' onClick={() =>{
                setModalDeleteData((prev: any) => ({ ...prev, showModal: false }))
            }}>
                No
          </Button>
          <Button onClick={() => deleteCompany()}>Yes</Button>
        </Modal.Footer>
      </Modal>
    )
} 