import {requests} from '../../services';
import {PARTNER_CAMPAIGN_URL, PARTNER_URL} from '../../utils/Endpoints';
import {BaseModel} from '../../model/shared/BaseModel';
import {User} from '../../model/user/User';
import {IPartnerCreate} from './dto/iPartnerCreate';
import {IReferredCampaign} from '../../model/campaign/IReferredCampaign';
import {IPartnerCampaign} from '../../model/campaign/IPartnerCampaign';

export const PartnerService = {
    getPartners: (params: any): Promise<BaseModel<User>> => requests.get(PARTNER_URL, params),
    createParner: (user: IPartnerCreate) => requests.post(PARTNER_URL, user),
    getCampaigns: (params: any): Promise<BaseModel<IReferredCampaign>> => requests.get(PARTNER_CAMPAIGN_URL, params),
    getCampaign: (id: string): Promise<IReferredCampaign> => requests.get(`${PARTNER_CAMPAIGN_URL}/${id}`),
    createCampaign: (campaign: IPartnerCampaign) => requests.post(PARTNER_CAMPAIGN_URL, campaign),
    updateCampaign: (id: string, campaign: IPartnerCampaign): Promise<IReferredCampaign> => requests.put(`${PARTNER_CAMPAIGN_URL}/${id}`, campaign),
    deleteCampaign: (id: number) => requests.delete(`${PARTNER_CAMPAIGN_URL}/${id}`)
}
