import React from 'react'
import { Button, Modal } from 'react-bootstrap'

interface IModalAddConfirm{
    showModal: boolean,
    isClient: boolean | undefined,
    setShowConfirmModal: (args: any) => void,
    postCompanyPartner: () => void,
}

export default function ModalAddConfirm({showModal, isClient, setShowConfirmModal, postCompanyPartner}: IModalAddConfirm) {
  return (
    <Modal
        animation={false}
        show={showModal}
        onHide={() => {
            setShowConfirmModal((prev: any) => ({ ...prev, showModal: false }))
        }}
        className='confirm-modal-add'
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Confirm action
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
            Are you sure you want to add {isClient ? 'client' : 'supplier'}?
        </Modal.Body>

        <Modal.Footer>
            <Button onClick={() => postCompanyPartner()}>Yes</Button>
            <Button variant='danger' onClick={() => {
                setShowConfirmModal((prev: any) => ({ ...prev, showModal: false }))
            }}>
                No
            </Button>
        </Modal.Footer>
    </Modal>
    )
}
