import React, {useState} from 'react';
import {Button, Col, Form} from 'react-bootstrap';
import {IPartnerCreate} from '../dto/iPartnerCreate';
import {useSelector} from 'react-redux';
import {Contact} from '../../../model/shared/Contact';
import {PartnerService} from '../PartnerService';
import {toast} from 'react-toastify';
import {ADDED_USER} from '../../../enum/Messages';
import {LocalRoutes} from '../../../enum/LocalRoutes';
import {useHistory} from 'react-router';

export function PartnerForm() {

    const initialContact: Contact = {
        city: '',
        country: '',
        phone: '',
        province: '',
        street: '',
        uidNumber: '',
        zip: ''
    }

    const initialAgency: IPartnerCreate = {
        contact: initialContact,
        displayName: '',
        email: '',
        firstName: '',
        lastName: '',
        username: '',
        password:'',
        referralCode:''
    }

    const history = useHistory()

    const [user,setUser] = useState<IPartnerCreate>(initialAgency)

    const {country} = useSelector((state: any) => state.enums)

    function createUser(env:any) {
        env.preventDefault()
        PartnerService.createParner(user)
            .then((data)=>{
                if (data.id) {
                    history.push(LocalRoutes.PARTNER_LIST)
                }
                toast(ADDED_USER)
            }).catch((err) => {

            toast.error(`${err.message}`)
        })
    }

    function handleChange(evt: any, isContact: boolean = false) {
        const value = evt.target.value;
        if (isContact) {
            setUser({
                ...user,
                contact: {
                    ...user.contact,
                    [evt.target.name]: value
                }
            })
        }else{
            setUser({
                ...user,
                [evt.target.name]: value
            });
        }
    }

    return(

        <>
            <div className="card card-custom mb-5 p-4">
                <h4>Personal info</h4>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Username</Form.Label>
                            <Form.Control name="username" onChange={handleChange} defaultValue={user?.username} />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>Email</Form.Label>
                            <Form.Control name="email" onChange={handleChange} defaultValue={user?.email} type="email"  />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Plain password</Form.Label>
                            <Form.Control name="password" onChange={handleChange} defaultValue={user?.password} />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>Display name</Form.Label>
                            <Form.Control name="displayName" onChange={handleChange} defaultValue={user?.displayName} />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>First name</Form.Label>
                            <Form.Control name="firstName" onChange={handleChange} defaultValue={user?.firstName} />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>Last name</Form.Label>
                            <Form.Control name="lastName" onChange={handleChange} defaultValue={user?.lastName} />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group className='col-md-6' >
                            <Form.Label>Referral code</Form.Label>
                            <Form.Control name="referralCode" onChange={handleChange}/>
                        </Form.Group>
                    </Form.Row>

                </Form>
            </div>

            {/*CONTACT*/}

            <div className="card card-custom mb-5 p-4">
                <h4>Contact</h4>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control name="phone" onChange={e => (handleChange(e, true))} defaultValue={user?.contact.phone ?? ""} />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>Street, House Number, Appartment Number</Form.Label>
                            <Form.Control name="street" onChange={e => (handleChange(e, true))} defaultValue={user?.contact.street}  />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Postal Code</Form.Label>
                            <Form.Control name="zip" onChange={e => (handleChange(e, true))} defaultValue={user?.contact.zip} />
                        </Form.Group>

                        <Form.Group as={Col}>
                            <Form.Label>City</Form.Label>
                            <Form.Control name="city" onChange={e => (handleChange(e, true))} defaultValue={user?.contact.city} />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>State</Form.Label>
                            <Form.Control name="province" onChange={e => (handleChange(e, true))} defaultValue={user?.contact.province} />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridState">
                            <Form.Label>Country</Form.Label>
                            <Form.Control name="country" onChange={e => (handleChange(e, true))} as="select" defaultValue={user?.contact.country}>
                                {country.map((country: string) => <option key={country}>{country}</option>)}
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group className='col-md-6' >
                            <Form.Label>VAT number</Form.Label>
                            <Form.Control name="uidNumber"  defaultValue={user?.contact.uidNumber} />
                        </Form.Group>
                    </Form.Row>

                </Form>
            </div>
            <Button onClick={createUser} size='lg' variant="primary" type="submit">
                Save
            </Button>

        </>

    )
}
