import React, {useEffect, useState} from 'react';
import { TabSwitch } from '../../../shared/components/TabSwitch';
import {AmbassadorService} from '../AmbassadorService';
import {User} from '../../../model/user/User';
import {Button, Table} from 'react-bootstrap';
import {Pagination} from '../../../shared/components/Pagination';
import {toast} from 'react-toastify';
import {AMBASSADOR_APPROVE, AMBASSADOR_REJECT} from '../../../enum/Messages';

export interface ITabs {
    [key: string]: any
}

export function AmbassadorList() {
    const [ambassador, setAmbassador] = useState<User[]>([])
    const [activeTab, setActiveTab] = useState("tab1")
    const [totalPages, setTotalPages] = useState(0);
    const [page, setPage] = useState(1);

    const [state, setState] = useState({
        page:1,
        perPage: 15,
        ambassadorStatus: "pending"
    })

    const tabs: ITabs = {
        tab1: "pending",
        tab2: "approved",
        tab3: "rejected",
    };

    useEffect(()=>{
       setState({...state, ambassadorStatus: tabs[activeTab]})
    },[activeTab])

    useEffect(()=>{
        AmbassadorService.getAmbassador(state)
            .then((data)=>{
                setAmbassador(data.data)
                setTotalPages(Math.ceil(data.count / state.perPage))
            }).catch((err) => {
            toast.error(`${err.response.data.message}`)
        });
    },[JSON.stringify(state)])

    function updateAmbasador(id: number, type: string) {
        AmbassadorService.updateAmbassador(id, type)
            .then(data=>{
               type === "rejected" ? toast(AMBASSADOR_REJECT) : toast(AMBASSADOR_APPROVE)
            }).catch(err=>{
            toast.error(`${err.response.data.message}`)
        })
    }

    const handlePages = (updatePage: number) => {
        setState({...state, page: updatePage})
        setPage(updatePage)
    };
    
    return (
        <div className="card card-custom mb-5 p-4 mt-3">
                <TabSwitch
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    tabName={'Pending'}
                    tabName2={"Approved"}
                    tabName3={"Rejected"}
                />

            <Table hover striped responsive>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Display name</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                {ambassador?.map((e)=>(
                    <tr key={e.id}>
                        <td>{e.id}</td>
                        <td>{e.displayName}</td>
                        <td>{e.username}</td>
                        <td>{e.email}</td>
                        <td>
                            { activeTab === "tab1" ?
                            <div>
                                <Button className="mr-1" onClick={() => updateAmbasador(e.id ?? 0, "approved")}>
                                    Approve
                                </Button>
                                <Button variant="danger" onClick={()=>updateAmbasador(e.id ?? 0, "rejected")}>
                                Reject
                                </Button>
                            </div>  : ""
                            }
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
            <div className="w-100">
                { totalPages > 1 &&
                    <Pagination
                        page={page}
                        totalPages={totalPages}
                        handlePagination={handlePages}
                    />
                }
            </div>
        </div>
    )
}