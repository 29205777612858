import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router';
import {OfferService} from '../OfferService';
import {toast} from 'react-toastify';
import {Button, Form, Card, Row, Col} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {UPDATED_OFFER} from '../../../enum/Messages';
import {formatDate, getCorrectEnums} from "../../../utils/Functions";
import {Platform} from "../../../model/platform/Platform";
import {FaCheck, FaTimes} from "react-icons/fa";

export function OfferEdit() {
    const [status, setStatus] = useState('');
    const {offerStatus} = useSelector((state:any)=> state.enums);
    const {state: {id}, state: {offer: offerState}}: any = useLocation();

    const history = useHistory();

    function editOffer() {
        OfferService.editOffer(id, {...offerState, status: status}).then((data)=>{
            toast(UPDATED_OFFER);
            history.push("/offers");
        }).catch(err=>{
            toast.error(`${err.response.data.message}`)
        })
    }

    function handleChange(evt: any) {
        const value = evt.target.value;
        setStatus(value)
    }

    useEffect(() => {
        setStatus(offerState.status);
    },[offerState])

    return (
        <Card>
            <Card.Body className="p-5 fw-bold font-size-h5">
                <div className="p-5 d-flex flex-row">
                    <div className="col-xs-6 col-md-4">
                        <p>Offer ID:<br/><span className="text-black-50">{offerState?.id}</span></p>
                        <p>Date of creation:<br/><span className="text-black-50">{formatDate(offerState?.createdAt)}</span></p>
                        {['accepted','completed'].includes(offerState.status) && <p>Date of acceptance:<br/><span className="text-black-50">{formatDate(offerState?.acceptedDate)}</span></p>}
                        <p>Company:<br/><span className="text-black-50">{offerState?.campaign?.company?.displayName}</span></p>
                        <p>Influencer:<br/><span className="text-black-50">{offerState?.user?.displayName}</span></p>
                        {offerState?.counterOfferBy ? <p>Counter by:<br/><span className="text-black-50">{offerState?.counterOfferBy?.displayName}</span></p>:''}
                    </div>
                    <div className="col-xs-6">
                        <p>Campaign: <br/><span
                            className="text-black-50 word-break">{offerState?.campaign?.id} {offerState?.campaign?.title}</span>
                        </p>
                        <p>Current: <br/>{offerState?.isCurrent ?
                            <span className="word-break text-primary"><FaCheck/></span> :
                            <span className="word-break text-danger"><FaTimes/></span>}
                        </p>
                        {offerState?.user?.agencyCompany &&  (
                            <p>
                                Agency:<br/>
                                <span className="text-black-50">{offerState?.user?.agencyCompany?.displayName}</span>
                            </p>
                        )}
                        <h5 className="font-weight-bold">Platforms</h5>
                        <div className="d-flex align-items-center flex-column flex-md-row">
                            <div className="w-md-75">
                                {offerState?.totalPlatforms?.map((item: Platform) => (
                                    <Row key={item.id} className="align-items-center my-2">
                                        <Col md={6} xs={9} className="d-flex align-items-center">
                                            <img src={item.path} width={30} alt={item.name}/>
                                            <span className="mb-0 ml-2">{item.name}</span>
                                        </Col>
                                        <Col md={6} xs={2}>
                                            <span
                                                className="text-black-50 mb-0 ml-6">x{item.nbPostsTotal}</span>
                                        </Col>
                                    </Row>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <Form.Label>Status:</Form.Label>
                    <Form.Control className="text-black-50 font-size-h5" name="status" as="select" value={status} onChange={handleChange}>
                        {offerStatus.map((status: string) => <option key={status} value={status}>{getCorrectEnums(status)}</option>)}
                    </Form.Control>
                </div>
                <Button className="col-md-1 col-sm-12 mt-4" onClick={() => editOffer()} size='lg' variant="primary" type="submit">
                    Save
                </Button>
            </Card.Body>
        </Card>
    )
}
