import React, {useEffect, useState} from 'react';
import {User} from '../../../model/user/User';
import {Campaign} from '../../../model/campaign/Campaign';
import {UserService} from '../../user/UserService';
import {toast} from 'react-toastify';
import {CampaignsService} from '../../campaigns/CampaignsService';
import {Button, Col, Form, FormControl, FormLabel, Row} from 'react-bootstrap';
import AutocompleteSelect from '../../../shared/components/AutocompleteSelect';
import {PartnerService} from '../PartnerService';
import {IPartnerCampaign} from '../../../model/campaign/IPartnerCampaign';
import {SUCCESSFULLY} from '../../../enum/Messages';
import {useLocation} from 'react-router';
import {IReferredCampaign} from '../../../model/campaign/IReferredCampaign';

export function PartnerCampaignForm() {
    const {state: {id, isEdit}}: any = useLocation();
    const [params, setParams] = useState({
        page:1,
        perPage: 100000
    })

    const [partnerCampaigns, setPartnerCampaigns] = useState<IPartnerCampaign>({
        influencerBookingBudget: 0,
        isManaged: false,
        totalBookingBudget: 0,
        campaign: "",
        user: ""
    })

    const [partnerCampaign, setPartnerCampaign] = useState<IReferredCampaign>()

    const [users, setUsers] = useState<User[]>([])
    const [campaign, setCampaign] = useState<Campaign[]>([])

    function createCampaign() {
        PartnerService.createCampaign(partnerCampaigns)
            .then((data)=>{
                toast(SUCCESSFULLY)
            }).catch((err) => {
            toast.error(`${err.response.data.message}`)
        });
    }

    function editCampaign() {
       PartnerService.updateCampaign(id,partnerCampaigns)
           .then((data)=>{
               setPartnerCampaign(data)
               toast(SUCCESSFULLY)
           }).catch((err) => {
           toast.error(`${err.response.data.message}`)
       });
    }

    useEffect(()=>{
        if (isEdit) {
            PartnerService.getCampaign(id)
                .then((data)=>{
                    setPartnerCampaign(data)
                    setPartnerCampaigns({
                        campaign: String(data.campaign.id),
                        influencerBookingBudget: data.influencerBookingBudget,
                        totalBookingBudget: data.totalBookingBudget,
                        user: `${data.user.id}`,
                        isManaged: data.isManaged ?? false
                    })
                }).catch((err) => {
                toast.error(`${err.response.data.message}`)
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        UserService.getUsers(params)
            .then((data) => {
                setUsers(data.data);
            })
            .catch((err) => {
                toast.error(`${err.response.data.message}`)
            });

        CampaignsService.getCampaigns(params)
            .then((data) => {
                setCampaign(data.data);
            })
            .catch((err) => {
                toast.error(`${err.response.data.message}`)
            });
    }, [])


    function setUserId(user: string){
        setPartnerCampaigns({
            ...partnerCampaigns,
            user: user
        })
    }

    function setCampaignId(campaign: string){
        setPartnerCampaigns({
            ...partnerCampaigns,
            campaign: campaign
        })
    }

    function handleChange(evt: any) {
        const value = +evt.target.value;
        setPartnerCampaigns({
            ...partnerCampaigns,
            [evt.target.name]: value
        });
    }

    function handleCheckbox(evt: any) {
        const change = evt.target.checked;
        setPartnerCampaigns({
            ...partnerCampaigns,
            [evt.target.name]: change
        });
    }

    return (
        <>
            <div className="card card-custom p-4">
                <Form className='form-group'>
                    <Row>
                        <Col>
                            <AutocompleteSelect list={users} listKey={"firstName"} updateState={setUserId} defaultValue={partnerCampaign?.user.displayName} labelName={"User"}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <AutocompleteSelect list={campaign} listKey={"title"} updateState={setCampaignId} defaultValue={partnerCampaign?.campaign.title} labelName={"Campaign"}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormLabel>Total booking budget</FormLabel>
                            <FormControl value={partnerCampaign?.totalBookingBudget} type="number" onChange={handleChange} name="totalBookingBudget"/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormLabel>Influencer booking budget</FormLabel>
                            <FormControl value={partnerCampaign?.influencerBookingBudget} type="number" onChange={handleChange} name="influencerBookingBudget" />
                        </Col>
                    </Row>

                    <Form.Group className="mt-3" id="formGridCheckbox">
                        <Form.Check className='d-flex pl-0' style={{gap: '10px'}} onChange={handleCheckbox} name="isManaged" type="checkbox" label="Managed" defaultChecked={partnerCampaign?.isManaged} />
                    </Form.Group>

                    <Button className="mt-4" variant="primary" onClick={isEdit ? editCampaign : createCampaign} >
                        Save
                    </Button>
                </Form>
            </div>
        </>
    )
}
