import React, {useEffect, useState} from 'react';
import {Button, Card, Form, Table} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {Pagination} from '../../../shared/components/Pagination';
import {BudgetOverviewService} from './BudgetOverviewService';
import {downloadFileRequest} from '../../../services';
import {CAMPAIGN_BUDGET_DOWNLOAD_URL} from '../../../utils/Endpoints';
import {IBudget} from '../../statistics/dto/iBudget';
import {formatDate} from "../../../utils/Functions";
import {ImpersonateEye} from "../../../shared/components/ImpersonateEye";
import {priceSplitter} from "../../../helpers/NumberFormater";

export function BudgetOverview() {
    const [state, setState] = useState({
        page:1,
        perPage: 15,
    })

    const [campaign, setCampaign] = useState(true);
    const [campaignId, setCampaignId] = useState("");
    const [budget, setBudget] = useState<IBudget[]>([]);

    const [totalPages, setTotalPages] = useState(0);

    // useEffect(() => {
    //     CampaignsService.getCampaigns({perPage: 10000})
    //         .then((data) => {               
    //             // setCampaign(data.data);
    //         })
    //         .catch((err) => {
    //             toast.error(`${err.response.data.message}`)
    //         });
    // }, []);

    useEffect(()=>{
        BudgetOverviewService.getBudgetOverview(campaignId,state)
            .then((data)=>{
                setBudget(data.data)
                setState({...state, page: !!data ? data?.page : 1})
                setTotalPages(Math.ceil(data.count / state.perPage))
            }).catch((err)=>{
            toast.error(`${err.message}`)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[JSON.stringify(state), campaign])


    const handlePages = (updatePage: number) => {
        setState({...state, page: updatePage})
    };

    return(
        <>
            <div className="card card-custom mb-5 p-4 mt-3">
                <Form.Row className="justify-content-between align-items-center">
                    <Form.Group className="col-md-7 col-sm-12 m-0">
                        <Form.Control type='text' placeholder='Campaign ID' className="form-control-solid"
                            onChange={(e) => setCampaignId(e.target.value)} 
                        />
                    </Form.Group>

                    <Form.Group className='mb-0 col'> 
                        <Button 
                            onClick={()=>{
                                setCampaign(!campaign);
                                setState({...state, page:1});
                            }} 
                            className='w-100'
                        >
                            Search
                        </Button>
                    </Form.Group>
                    
                    <Form.Group className='mb-0 col'>
                        <Button className='w-100' onClick={()=>downloadFileRequest(CAMPAIGN_BUDGET_DOWNLOAD_URL, 'export-budget')}>
                            Export
                        </Button>
                    </Form.Group>
                </Form.Row>
            </div>

            <Card className="card-custom mb-5 p-4 table-responsive">
                <Table hover>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Id</th>
                            <th>Campaign</th>
                            <th>Email</th>
                            <th>Status</th>
                            <th>Approval</th>
                            <th>Planned budget</th>
                            <th>Total spent</th>
                        </tr>
                    </thead>
                    <tbody>
                    {budget?.map((e)=>(
                        <tr key={e.id}>
                            <td>{formatDate(e.createdAt)}</td>
                            <td>{e.id}</td>
                            <td>{e.title}</td>
                            <td><ImpersonateEye id={e?.userId} />{e.email}</td>
                            <td>
                                <span className={`status ${e?.status}`}>
                                    {e?.status === 'published' ? 'public': e.status}
                                </span>
                            </td>
                            <td>
                                <span className={`status ${e?.approvalStatus}`}>
                                    {e?.approvalStatus}
                                </span>
                            </td>
                            <td className='text-right'>{priceSplitter(e.grossBudget ?? 0)} €</td>
                            <td className={`${e.grossBudget > e.totalSpent ? 'text-danger' : 'text-primary'} text-right`}>
                                {priceSplitter(e.totalSpent)} €
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <div className="w-100">
                    { totalPages <= 1 ? '' :
                        <Pagination
                            page={state?.page}
                            totalPages={totalPages}
                            handlePagination={handlePages}
                        />
                    }
                </div>
            </Card>
        </>
    )
}
