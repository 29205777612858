import axios, {AxiosResponse} from 'axios';
import {ErrorToast, SuccessToast} from './toasters';
import {BASE_URL} from './Endpoints';
import {actions} from '../store/authentication/authRedux';
import store from '../store/store';
import history from './history';

const defaultOptions = {
    headers: {
        'Content-Type': 'application/json',
        'accept': 'text/html',
    },
};

export const instance = axios.create(defaultOptions);

instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem("token") ?? "";
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
});

instance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const refreshToken = localStorage.getItem('refreshToken');
            if (error.response.status === 403) {
                ErrorToast(error.response.data);
                history.push('/403');
                return;
            }
            if (refreshToken) {
                try {
                    const response = await axios.post(`${BASE_URL}auth/refresh`, {refreshToken});
                    // don't use axious instance that already configured for refresh token api call
                    const newAccessToken = response?.data.token;
                    localStorage.setItem('token', newAccessToken);  //set new access token
                    originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
                    return axios(originalRequest); //recall Api with new token
                } catch (error) {
                    ErrorToast(error);
                    localStorage.clear();
                    store.dispatch(actions.logout());
                    // Handle token refresh failure
                    // mostly logout the user and re-authenticate by login again
                }
            }
        }
        return Promise.reject(error);
    }
);


const responseBody = (response: AxiosResponse) => response.data;

export const requests = {
    get: (url: string, params?: any) => instance.get(url, {params}).then(responseBody).catch(e => {
        throw e;
    }),
    post: (url: string, body?: {}) => instance.post(url, body).then(responseBody).catch(e => {
        throw e;
    }),
    put: (url: string, body: {}) => instance.put(url, body).then(responseBody).catch(e => {
        throw e;
    }),
    delete: (url: string) => instance.delete(url).then(responseBody).catch(e => {
        throw e;
    }),
};

export const downloadFileRequest = (url: string, format?: string, fileName?: string | null, params?: any) => {
    const token = localStorage.getItem('token') ?? '{}';

    const method = 'GET';
    axios.request({
        url,
        method,
        responseType: 'blob',
        headers: {
            Authorization: token ? `Bearer ${token}` : '',
        },
        params: params 
    })
        .then((response) => {
            fileName = response.headers['content-disposition'].slice(22, response.headers['content-disposition'].length - 1);
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `${fileName}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            SuccessToast('Successfully download');   
        }).catch(error => console.log(error));
};

export const downloadFileRequestLoading = (url: string, format: string, fileName?: string | null, params?: any): Promise<{loading: boolean}> => {
    const token = localStorage.getItem('token') ?? '{}';

    const method = 'GET';
    return new Promise((resolve, reject) => {
        axios.request({
            url,
            method,
            responseType: 'blob',
            headers: {
                Authorization: token ? `Bearer ${token}` : '',
            },
            params: params 
        })
        .then((response) => {          
            fileName = response.headers['content-disposition'].split('filename=')[1].split(';')[0];
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `${fileName}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            SuccessToast('Successfully download');   
            resolve({ loading: false });
        }).catch(error => {
            reject(error); 
        });
    });
};


export function multipartFormData(image: any, url: string, body?: any, method = 'put') {
    const formData = new FormData();
    formData.append('file', image);
    formData.append('body', body);
    if (method === 'put') {
        return instance.put(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    } else {
        return instance.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }
}
