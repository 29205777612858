import React from "react";
import {Button, Modal} from "react-bootstrap";

export interface IReviewModal {
    show: boolean,
    closeModal: () => void
    handleSubmit: (id: number, status: string) => void
    setDeclineReason: React.Dispatch<React.SetStateAction<{ declineReason: string }>>
    id: number
    declineReason: string
}

export function ReviewModal({show, closeModal, handleSubmit, setDeclineReason, id, declineReason}: IReviewModal) {

    // const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    //     const {value, name} = event.target;
    //     setDeclineReason(prev => ({...prev, [name]: value}))
    // }

    const declineCampaign = () => {
        handleSubmit(id, 'rejected')
        closeModal();
    }

    return (
        <Modal
            show={show}
            onHide={closeModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Campaign communication
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Are you sure that you want to decline this campaign?</h4>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={closeModal}>Close</Button>
                <Button variant="danger" onClick={declineCampaign}>Decline</Button>
            </Modal.Footer>
        </Modal>
    )
}