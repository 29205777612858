import React, {Component} from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import * as auth from "../store/authentication/authRedux";
import {LayoutSplashScreen} from "../shared/layout";


class Logout extends Component {
    componentDidMount() {
        this.props.logout();
    }

    render() {
        const {hasAuthToken} = this.props;
        return hasAuthToken ? <LayoutSplashScreen/> : <Redirect to="/"/>;
    }
}

export default connect(
    ({auth}) => ({hasAuthToken: Boolean(auth.authToken)}),
    auth.actions
)(Logout);
 