import React, {useEffect, useState} from 'react';
import {Button, ButtonGroup, Table} from 'react-bootstrap';
import {FaEye} from 'react-icons/all';
import {Pagination} from '../../../shared/components/Pagination';
import {IReferredCampaign} from '../../../model/campaign/IReferredCampaign';
import {toast} from 'react-toastify';
import {PartnerService} from '../PartnerService';
import {LocalRoutes} from '../../../enum/LocalRoutes';
import {SUCCESSFULLY} from '../../../enum/Messages';
import {useHistory} from 'react-router';

export function PartnerCampaign() {
    const history = useHistory();
    const [state, setState] = useState({
        page: 1,
        perPage: 15
    })

    const [campaign, setCampaign] = useState<IReferredCampaign[]>([])
    const [totalPages, setTotalPages] = useState(0);
    const [change, setChange] = useState(false);

    useEffect(() => {
        PartnerService.getCampaigns(state)
            .then((data) => {
                setCampaign(data.data)
                setTotalPages(Math.ceil(data.count / state.perPage))
            }).catch((err) => {
            toast.error(`${err.response.data.message}`)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(state), change])

    function deleteCampaign(id: number) {
        PartnerService.deleteCampaign(id)
            .then((data) => {
                setChange(!change)
                toast(SUCCESSFULLY)
            }).catch((err) => {
            toast.error(`${err.response.data.message}`)
        });
    }

    const handlePages = (updatePage: number) => {
        setState({...state, page: updatePage})
    };

    return (
        <>
            <div className="card card-custom mb-5 p-4 mt-3">
                <Button variant="outline-primary" className="mb-3 w-25"
                        onClick={() => history.push(LocalRoutes.PARTNER_CREATE, {id: 0, isEdit: false})}>
                    + Add partners campaign
                </Button>

                <div className="table-responsive">
                    <Table hover>
                        <thead>
                        <tr>
                            <th>Id</th>
                            <th>Title</th>
                            <th>Brand</th>
                            <th>Partner</th>
                            <th>Total budget</th>
                            <th>Influencer budget</th>
                            <th>Date</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {campaign.map((e) => (
                            <tr key={e.id}>
                                <td>{e.id}</td>
                                <td>{e.campaign.title}</td>
                                <td>{e.campaign.brand.name}</td>
                                <td><FaEye color="lightblue"/> {e.user.displayName}</td>
                                <td>{e.totalBookingBudget}</td>
                                <td>{e.influencerBookingBudget}</td>
                                <td>{e.createdAt}</td>
                                <td>
                                    <ButtonGroup>
                                        <Button className="mr-1" onClick={() => history.push(LocalRoutes.PARTNER_CREATE, {
                                            id: e.id,
                                            isEdit: true
                                        })}>
                                            Edit
                                        </Button>
                                        <Button variant="danger" onClick={() => deleteCampaign(e.id ?? 0)}>Delete</Button>
                                    </ButtonGroup>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </div>
                <div className="w-100">
                    {totalPages <= 1 ? '' :
                        <Pagination
                            page={state.page}
                            totalPages={totalPages}
                            handlePagination={handlePages}
                        />
                    }
                </div>
            </div>
        </>
    )
}
