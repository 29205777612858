import React, {useEffect, useState} from 'react';
import {Button, ButtonGroup, Col, Form, Table} from 'react-bootstrap';
import {Pagination} from '../../../shared/components/Pagination';
import {FaEye} from 'react-icons/all';
import {User} from '../../../model/user/User';
import {PartnerService} from '../PartnerService';
import {useHistory} from 'react-router';

export function PartnerList() {
    const history = useHistory();
    const [state, setState] = useState({
        page: 1,
        perPage: 15,
        displayName: '',
        email: ''
    })

    const [partners, setPartners] = useState<User[]>([])
    const [totalPages, setTotalPages] = useState(0);
    const [change, setChange] = useState(false)

    useEffect(() => {
        PartnerService.getPartners(state)
            .then((data) => {
                setPartners(data.data)
                setTotalPages(Math.ceil(data.count / state.perPage))
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [change])

    const handlePages = (updatePage: number) => {
        setState({...state, page: updatePage})
    };

    function handleClick(env: any) {
        env.preventDefault()
        setChange(!change)
    }

    function handleChange(evt: any) {
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value
        });
    }

    return (

        <>
            <div className="card card-custom mb-5 p-4 mt-3">
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Control className='form-control-solid' placeholder='Name' name="displayName" onChange={handleChange}/>
                    </Form.Group>

                    <Form.Group as={Col}>
                        <Form.Control className='form-control-solid' placeholder='Email' name="email" onChange={handleChange}/>
                    </Form.Group>
                </Form.Row>

                <Button className="col-md-1 col-sm-12" type="submit" onClick={handleClick}>
                    Search
                </Button>
            </div>

            <div className="card card-custom mb-5 p-4 mt-3 table-responsive">
                <Table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Display name</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {partners.map((e) => (
                        <tr key={e.id}>
                            <td>{e.id}</td>
                            <td>{e.displayName}</td>
                            <td>{e.username}</td>
                            <td>{e.email}</td>
                            <td>
                                <ButtonGroup>
                                    <Button className="mr-1"><FaEye color="white"/></Button>
                                    <Button onClick={() => history.push(`/user/edit${e.id}`)}>
                                        Edit
                                    </Button>
                                </ButtonGroup>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <div className="w-100">
                    {totalPages <= 1 ? '' :
                        <Pagination
                            page={state.page}
                            totalPages={totalPages}
                            handlePagination={handlePages}
                        />
                    }
                </div>
            </div>
        </>
    )
}