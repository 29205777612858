import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Table} from 'react-bootstrap';
import {useHistory} from 'react-router';
import {NavLink} from 'react-router-dom';
import {toast} from 'react-toastify';
import {ICampaignsGoals} from '../../../model/campaign/ICampaignsGoals';
import {Pagination} from '../../../shared/components/Pagination';
import {CampaignsService} from '../CampaignsService';

export function CampaignGoals() {
    const history = useHistory();
    const [state, setState] = useState({
        page: 1,
        perPage: 15,
        title: '',
    })

    const [campaignGoal, setCampaignGoal] = useState<ICampaignsGoals[]>([])
    const [change, setChange] = useState(false)
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        CampaignsService.getCampaignsGoals(state)
            .then((data) => {
                setCampaignGoal(data.data)
                setTotalPages(Math.ceil(data.count / state.perPage))
            })
            .catch((err) => {
                toast.error(`${err?.response?.data?.message}`)
            });
        return () => {
        };
    }, [change, page]);


    function handleClick(event: any) {
        event.preventDefault()
        setChange(!change)
    }

    function handleChange(event: any) {
        const value = event.target.value;
        setState({
            ...state,
            [event.target.name]: value
        });
    }

    const handlePages = (updatePage: number) => {
        setState({...state, page: updatePage})
        setPage(updatePage)
    };

    function handleCheckbox(id: number) {
        CampaignsService.campaignGoalActive(id)
    }

    return (
        <>
            <Button variant="outline-primary" onClick={() => history.push(`/campaign/goals/form`, {id: undefined, isEdit: false})}>
                + Add campaign goal
            </Button>

            <div className="card card-custom mb-5 p-4 mt-3">
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Control placeholder='Title' className='form-control-solid' name="title" onChange={handleChange}/>
                    </Form.Group>
                </Form.Row>
                <Button className="col-md-1 col-sm-12" onClick={handleClick} type="submit">
                    Search
                </Button>
            </div>

            <div className="card card-custom mb-5 p-4">
                <Table hover responsive>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Title</th>
                            <th>Summary</th>
                            <th>Story</th>
                            <th>Active</th>
                            <th>Image</th>
                            <th>Edit</th>
                        </tr>
                    </thead>
                    <tbody>
                    {campaignGoal.map((goal) => (
                        <tr key={goal.id}>
                            <td>{goal.id}</td>
                            <td>{goal.title}</td>
                            <td>{goal.summary.length > 70 ? goal.summary.substring(0, 69) + '...' : goal.summary}</td>
                            <td>{goal.story.length > 70 ? goal.story.substring(0, 69) + '...' : goal.story}</td>
                            <td>
                                <div className="d-flex align-items-center">
                                    <input className='form-check-input' type="checkbox" name="isActive" onChange={(event) => {
                                        handleCheckbox(goal.id)
                                    }}
                                           defaultChecked={goal.isActive}
                                           data-toggle="toggle"
                                    />
                                    <p className="m-0 pl-2">Active</p>
                                </div>
                            </td>
                            <td>
                                <img alt='' src={goal.goalImage ? goal?.goalImage?.thumbnail :"/images/placeholder.png"}
                                     style={{width: 70, height: 60}} className="rounded"/>
                            </td>
                            <td>
                                <NavLink className="btn btn-primary w-100 text-white" to={{
                                        pathname: '/campaign/goals/form',
                                        state: {id: goal.id, isEdit: true}
                                    }}>Edit
                                </NavLink>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <div className="w-100">
                    {totalPages <= 1 ? '' :
                        <Pagination
                            page={page}
                            totalPages={totalPages}
                            handlePagination={handlePages}
                        />
                    }
                </div>

            </div>

        </>
    )
}
