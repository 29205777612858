import React, {useEffect, useState} from 'react';
import {Button, Table, Row, Col, Modal, Form, DropdownButton, Dropdown, Card} from 'react-bootstrap';
import {UserService} from '../UserService';
import {toast} from 'react-toastify';
import {useLocation} from 'react-router';
import {ISocialCommand, PlatformItem} from '../../../model/platform/PlatformItem';
import {getCorrectEnums, getCorrectSocialUrl} from '../../../utils/Functions';
import {useSelector} from 'react-redux';
import {SuccessToast, WarningToast} from '../../../utils/toasters';
import {numberFormatter} from '../../../helpers/NumberFormater';
import {ImpersonateEye} from '../../../shared/components/ImpersonateEye';

export function UserPlatforms() {
    const [platforms, setPlatforms] = useState<PlatformItem[]>([]);
    const [updateList, setUpdateList] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [handle, setHandle] = useState('');
    const [userPlatformId, setUserPlatformId] = useState(0);
    const {state: {id}, state: {user}}: any = useLocation();
    const {authToken} = useSelector((reduxState: any) => reduxState.auth);

    useEffect(() => {
        UserService.getUserPlatforms(id)
            .then((data) => {
                setPlatforms(data.data)
            }).catch((err) => {
            toast.error(`${err.response.data.message}`)
        });
    }, [updateList,]);

    const disablePlatform = (id: number) => {
        UserService.disablePlatform(id)
            .then((response) => {
                setUpdateList(prevState => !prevState);
            }).catch((err) => {
            toast.error(`${err.response.data.message}`)
        });
    }

    const deletePlatform = (id: number) => {
        UserService.deletePlatform(id)
            .then((response) => {
                setUpdateList(prevState => !prevState);
            }).catch((err) => {
            toast.error(`${err.response.data.message}`)
        });
    }

    const updatePlatform = () => {
        if (!handle?.length) {
            return WarningToast('Handle must be at least 1 character')
        }
        UserService.changeHandleUserPlatforms(userPlatformId, {title: handle})
            .then((response) => {
                setPlatforms(response.data);
                setShowModal(false);
            }).catch((err) => {
            toast.error(`${err.response.data.message}`)
        });
    }

    const doReauthorize = async (platformId: number) => {
        const sendData: ISocialCommand = {
            skip: 0,
            take: 1,
            userId: id,
            platformId: platformId,
            followers: 0,
            content: 1,
        }

        try {
            await UserService.postReauthorize(sendData);
            SuccessToast('Successfully reauthorize');
            setUpdateList(true);
        } catch (error) {
            toast.error(`${error}`)
        }
    }

    return (
        <>
            <Row className="align-items-center">
                <Col className="col-auto">
                    <h3 className="m-0">{!!user?.displayName ? user?.displayName : user.firstName + ' ' + user.lastName}</h3>
                </Col>
                <Col className="col-auto">
                    <ImpersonateEye id={id} style='btn-icon btn-primary'/>
                </Col>
            </Row>
            <Card className="mb-5 p-4 mt-4">
                <Table hover responsive>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Handle</th>
                        <th>Social Link</th>
                        <th>Followers</th>
                        <th>Is Enabled?</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {platforms.map((platform, i, platformArr) => {
                        return (
                            <React.Fragment key={platform?.id}>
                                <tr hidden={platformArr[i]?.socialPlatform?.mainPlatform?.id ===
                                    platformArr[i - 1]?.socialPlatform?.mainPlatform?.id}>
                                    <th> {platform?.socialPlatform?.mainPlatform?.path ?
                                        <img alt="" src={platform?.socialPlatform?.mainPlatform?.path}
                                             style={{width: 30, height: 30}}/>
                                        : <img alt="" src="/images/placeholder.png"
                                               style={{width: 30, height: 30}}/>}
                                    </th>
                                    <th>{platform?.socialPlatform?.mainPlatform?.name ?? '-'}</th>
                                </tr>
                                <tr>
                                    <td>{platform.id}</td>
                                    <td>{getCorrectEnums(platform.socialPlatform.name)}</td>
                                    <td>{platform.title ?? '-'}</td>
                                    <td>{<a target="_blank"
                                            key={platform.id}
                                            className="d-flex align-items-center mb-2 mr-3"
                                            href={`${getCorrectSocialUrl(platform?.mainPlatform?.platformEnum as string, platform?.title, platform?.externalLink)}`}
                                            rel="noreferrer">
                                        <img src={platform?.socialPlatform?.path} alt="Platform icon"/>
                                        <span className="ml-1 mb-0">
                                        @{platform?.title}
                                         </span>
                                        </a>}
                                    </td>
                                    <td>{numberFormatter(platform.followers ? +platform.followers : 0)}</td>
                                    <td>
                                        <div className="d-flex align-items-center ">
                                            <input className="form-check-input" type="checkbox" name="isEnabled"
                                                   onChange={() => disablePlatform(platform.id ?? 0)}
                                                   checked={platform.isEnabled}
                                                   data-toggle="toggle"/>
                                            <p className="m-0 pl-2">Enabled</p>
                                        </div>
                                    </td>
                                    <td className="text-start">
                                        <DropdownButton title="Actions" id="dropdown-menu-align-right">
                                            <Dropdown.Item eventKey="1" onClick={() => {
                                                setShowModal(true);
                                                setHandle(platform.title);
                                                setUserPlatformId(platform.id);
                                            }}>
                                                Edit Handle
                                            </Dropdown.Item>
                                            {!!platform?.socialPlatform?.mainPlatform?.hasApi && (
                                                <Dropdown.Item eventKey="2"
                                                               onClick={() => doReauthorize(platform?.socialPlatform?.mainPlatform?.id!)}>
                                                    Reauthorize
                                                </Dropdown.Item>
                                            )}
                                        </DropdownButton>
                                    </td>
                                </tr>
                            </React.Fragment>
                        )
                    })}
                    </tbody>
                </Table>
            </Card>

            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit platform
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Handle</Form.Label>
                        <Form.Control as="input" type="text" name="handle"
                                      value={handle}
                                      onChange={(ev) => setHandle(ev.target.value.replace(/\s/g, ''))}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setShowModal(false)} variant="secondary">Close</Button>
                    <Button variant="primary" onClick={() => updatePlatform()}>Change</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
