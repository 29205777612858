import {Route, Switch} from "react-router-dom";
import React from "react";
import {PartnerList} from "./components/PartnerList";
import {PartnerForm} from "./components/PartnerForm";
import {PartnerCampaign} from "./components/PartnerCampaign";
import {PartnerCampaignForm} from "./components/PartnerCampaignForm";

export function PartnerRoutes() {
    return(
        <Switch>
            <Route exact path="/partner/partners" component={PartnerList}/>
            <Route path="/partner/create" component={PartnerForm}/>
            <Route exact path="/partner/campaign" component={PartnerCampaign}/>
            <Route path="/partner/campaign/create" component={PartnerCampaignForm}/>
        </Switch>
    )
}
