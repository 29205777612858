import {all} from "redux-saga/effects";
import {combineReducers} from '@reduxjs/toolkit';

import * as auth from "./authentication/authRedux";
import enumsSlice from "./common/enumsRedux";

export const rootReducer = combineReducers({
    auth: auth.reducer,
    enums: enumsSlice.reducer,
});

export function* rootSaga() {
    yield all([auth.saga()]);
}
