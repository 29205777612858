/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {MenuItem} from "./MenuItem";
import {
    FaBox,
    FaBuilding,
    FaChartLine,
    FaCheck,
    FaCheckSquare,
    FaCreditCard,
    FaFacebook,
    FaFileExport,
    FaFileInvoiceDollar,
    FaMailBulk,
    FaReply,
    FaSitemap,
    FaUniversalAccess,
    FaUserClock,
    FaUserFriends,
    FaUserTie
} from 'react-icons/fa';
import {SubMenuItem} from "./SubMenuItem";
import {IoGitPullRequestSharp} from "react-icons/all";
import {hasAccessRight} from '../../../../utils/Functions';
import {AdminAclEnum} from '../../../../enum/AdminAclEnum';

export function AsideMenuList({layoutProps}: any) {

    return (
        <ul role="menubar" className={`menu-nav ${layoutProps.ulClasses}`}>
            {hasAccessRight(AdminAclEnum.CAMPAIGNS) &&
                <MenuItem url={"/campaigns"} name={"Campaigns"} icon={<FaBox/>} isSubMenu={true}>
                    <SubMenuItem url={"/campaign/list"} name={"Campaigns"}/>
                    <SubMenuItem url={"/campaign/goals"} name={"Campaign goals"}/>
                    <SubMenuItem url={"/campaign/types"} name={"Campaign types"}/>
                    <SubMenuItem url={"/campaign/budget-range"} name={"Campaign budget range"}/>
                </MenuItem>
            }
            {hasAccessRight(AdminAclEnum.SUPERVISORS) &&
                <MenuItem url={"/supervisors"} name={"Supervisors"} icon={<FaUserTie/>}/>
            }
            {hasAccessRight(AdminAclEnum.OFFERS) &&
                <MenuItem url={"/offers"} name={"Offers"} icon={<FaCheck/>}/>
            }
            {hasAccessRight(AdminAclEnum.ACCESS_RIGHTS) &&

                <MenuItem url={"/access-right"} name={"Access Rights"} icon={<FaUniversalAccess/>}/>
            }
            {hasAccessRight(AdminAclEnum.PACKAGES) &&
                <MenuItem url={"/packages"} name={"Packages"} icon={<FaSitemap/>} isSubMenu={true}>
                    <SubMenuItem url={"/packages"} name={"Packages"}/>
                    <SubMenuItem url={"/packages/log"} name={"Packages Log"}/>
                </MenuItem>
            }
            {hasAccessRight(AdminAclEnum.EXPORT) &&
                <MenuItem url={"/export"} name={"Export"} icon={<FaFileExport/>} isSubMenu={true}>
                    <SubMenuItem url={"/export/export-offers"} name={"Offers"}/>
                </MenuItem>
            }
            {/*<MenuItem url={"/partner"} name={"Partners"} icon={<FaAd/>} isSubMenu={true}>*/}
            {/*    <SubMenuItem url={"/partner/partners"} name={"Partners"}/>*/}
            {/*    <SubMenuItem url={"/partner/create"} name={"Create partner"}/>*/}
            {/*    <SubMenuItem url={"/partner/campaign"} name={"Partner campaigns"}/>*/}
            {/*</MenuItem>*/}

            {/*<MenuItem url={"/ambassador"} name={"Ambassador"} icon={<FaClipboard/>} isSubMenu={true}>*/}
            {/*    <SubMenuItem url={"/ambassador/ambassadors"} name={"Ambassadors"}/>*/}
            {/*    <SubMenuItem url={"/ambassador/invoices"} name={"Ambassador invoices"}/>*/}
            {/*    <SubMenuItem url={"/ambassador/campaign"} name={"Referred campaigns"}/>*/}
            {/*</MenuItem>*/}

            {/* <MenuItem url={"/external"} name={"External search"} icon={<FaSearch/>}/> */}

            {/* <MenuItem url={"/credit"} name={"Credit requests"} icon={<FaReceipt/>}/> */}
            {hasAccessRight(AdminAclEnum.EMAILING) &&
                <MenuItem url={"/campaign/emailing"} name={"Campaign emailing"} icon={<FaMailBulk/>}/>
            }
            {hasAccessRight(AdminAclEnum.BUDGET) &&
                <MenuItem url={"/campaign/budget"} name={"Budget overview"} icon={<FaCreditCard/>}/>
            }
            {hasAccessRight(AdminAclEnum.AGENCY) &&
                <MenuItem url="/agency" name={"Agency companies"} icon={<FaUserFriends/>}/>
            }
            {hasAccessRight(AdminAclEnum.REPORTING) &&
                <MenuItem url="/report" name={"Reporting overview"} icon={<FaCheckSquare/>}/>
            }
            {hasAccessRight(AdminAclEnum.ONBOARDING) &&
                <MenuItem url="/onboarding-requests" name="Onboarding requests" icon={<IoGitPullRequestSharp/>}/>
            }
            {hasAccessRight(AdminAclEnum.STATISTICS) &&
                <MenuItem url="/statistics" name={"Statistics"} icon={<FaChartLine/>}/>
            }
            {/*<MenuItem url="/timeline" name={"Timeline"} icon={<FaClock/>}/>*/}
            {hasAccessRight(AdminAclEnum.COMPANY) &&
                <MenuItem url={"/company"} name={"Company"} icon={<FaBuilding/>}/>
            }
            {hasAccessRight(AdminAclEnum.INVOICES) &&
                <MenuItem url={"/invoices"} name={"Invoices"} icon={<FaFileInvoiceDollar/>}/>
            }
            {hasAccessRight(AdminAclEnum.PLATFORM) &&

                <MenuItem url={"/platform"} name={"Platform"} icon={<FaFacebook/>} isSubMenu={true}>
                    <SubMenuItem url={"/platform/platforms"} name="Platforms"/>
                    <SubMenuItem url={"/platform/item"} name="Platforms item"/>
                </MenuItem>
            }
            {hasAccessRight(AdminAclEnum.USER_PLATFORM_LOGS) &&
                <MenuItem url="/user/platform/logs" name={"User platform logs"} icon={<FaUserClock/>}/>
            }
            {hasAccessRight(AdminAclEnum.REPORT_ITEMS) &&
                <MenuItem url={"/reported"} name={"Reports"} icon={<FaReply/>}/>
            }
        </ul>
    );
}
